:root {
  --amplify-colors-primary-80: #1d58fc;
  --amplify-colors-red-80: #f00c5e;
  --amplify-components-loader-width: 16px;
  --amplify-components-loader-height: 16px;
  --amplify-components-flex-gap: 16px;
  --amplify-components-fieldcontrol-font-size: 16px;
  --amplify-components-icon-height: 16px;
  /* Font */
  --main-font: Figtree, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
  sans-serif;
  --amplify-font-sizes-large: 20px;

  /* Button */
  --amplify-components-button-border-radius: 48px;
  --amplify-components-button-font-size: 16px;
  --amplify-components-button-padding-block-start: 14px;
  --amplify-components-button-padding-block-end: 14px;
  --amplify-components-button-padding-inline-start: 24px;
  --amplify-components-button-padding-inline-end: 24px;
  --amplify-components-button-line-height: 20px;
  --amplify-components-button-primary-hover-background-color: #4a79fd;
}

body {
  font-family: var(--main-font);
}

.amplify-liveness-error-modal {
  justify-content: flex-start !important;
}

.amplify-liveness-error-modal .amplify-liveness-error-modal__heading {
  color: var(--amplify-colors-red-80);
  margin-bottom: 16px;
}

.amplify-liveness-error-modal .amplify-alert__icon {
  display: none;
}

.amplify-liveness-cancel-container {
  display: none;
}

.amplify-liveness-landscape-error-modal {
  flex-direction: column;
  height: auto !important;
}

.amplify-liveness-match-indicator__bar {
  border: none;
  background-color: #d2defe;
}

.amplify-icon {
  width: 16px !important;
  height: 16px !important;
}